import {
    Form,
    Row,
    Col,
    Button,
    InputGroup,
    Modal,
    Card,
  } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect, useState } from "react";
import useDataCountry from "../../../hooks/useDataCountry";
import { mnBankNameList } from "../dashboard/actions/instruction";
import { getCountryList } from "../registration/actions/Registration";
import { useTranslation } from "react-i18next";
import { getCurrencyDDList } from "./actions/payments";
import { faDownLeftAndUpRightToCenter, faDownload, faHandPointLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";
import configData from "./config/bankReferenceManuals.json"


const PaymentRefUserGuid = ({showPaymentRefInfo,setPaymentRefInfo,selectedCurrency}:any) => {
    const countryDDList = useSelector(
        (state: RootState) => state.standaloneInvoice.countryDDList
      );
      const dataCountry = useDataCountry();  
      const linerId = useSelector(
        (state: RootState) => state.navbarTop.linerId
      );
      const dispatch = useDispatch<AppDispatch>();

      const mnBankNmOption = useSelector(
        (state: RootState) => state.payment.mnBankNmOption
      );
      const [ccy,setCCY]= useState("");
      const [country, setCountry] = useState('') 

      const [mnBankNmList, setMnBankNmList] = useState('');
      const countryOptions = useSelector(
        (state: RootState) => state.registration.countryOptions
      );
      const { t } = useTranslation();
      console.log("Link",mnBankNmList); 

      console.log("mnBankNmOption",mnBankNmList,country,ccy,linerId);

      useEffect(() => {
        if(!countryDDList || countryDDList.length < 1)dispatch(getCurrencyDDList())
      },[]);
     
      useEffect(()=>{
        if(dataCountry&&linerId&&showPaymentRefInfo){
        dispatch(mnBankNameList({dataCountry, linerId})).unwrap().then((data)=>{
          if(data?.[0]){
            setMnBankNmList(data[0]?.text??'')
          }
        })
        dispatch(getCountryList(0))
        setCountry(dataCountry);
      }
      },[dataCountry, linerId, showPaymentRefInfo])

      useEffect(()=>{
        if(selectedCurrency && countryDDList.find((x)=>x.currencyShortName == selectedCurrency)){
          setCCY(selectedCurrency)
        } else if(countryDDList?.[0]?.currencyShortName){
          setCCY(countryDDList?.[0]?.currencyShortName)
        }
      },[selectedCurrency, countryDDList])
      const pdfConfig: any = configData
      const pdfName = pdfConfig?.[`${mnBankNmList}_${ccy}_${country}_${linerId}`]??null

    
//
//console.log("showPaymentRefInfo",setPaymentRefInfo);
    return <Modal show={showPaymentRefInfo} onHide={() => setPaymentRefInfo(false)} size="lg">
         
        <Modal.Body>
     
      <div className="row">
        <div className="col-lg-4 mb-3">
        <Form.Group controlId='registration.country'>
              <Form.Label className='fs--1'>
                  {t('resource_1:currency')} 
              </Form.Label> 
        <Form.Select
          disabled={
             false
          }
          value={ccy}
          onChange={(e) => {
            setCCY(e.target.value)
          }}
          
          className='fs--1 form-control  form-select'>
          {
            countryDDList.map((r:any) => {
                return (
                    <>
                        <option value={r?.currencyCode} key={r?.currencyShortName}>
                            {r?.currencyShortName}
                        </option>
                    </>
                )
            })
          }
        </Form.Select>
        </Form.Group>
        </div>
        <div className="col-lg-4 mb-3">
        <Form.Group >
              <Form.Label className='fs--1'>
                  {t('resource_2:bankName')} 
              </Form.Label>
                  <Form.Select
                    className='fs--1'
                    
                    value={mnBankNmList}
                    onChange={(e) => {
                      setMnBankNmList(e.target.value)
                    }}
                  >
                    {mnBankNmOption.map((option: any) => (
                      <option key={option.value} value={option.text}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
            </Form.Group>
            </div>
            <div className="col-lg-4 mb-3"> 
            <Form.Group controlId='registration.country'>
              <Form.Label className='fs--1'>
                {t('resource_1:country')} 
              </Form.Label>

              <Form.Select
                
                value={
                  country

                }
               
               onChange={(e) => {
                  setCountry(e.target.value);
                }} >
                {countryOptions.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
                
              </Form.Select>

              
            </Form.Group>
            </div>   
            <div className="col-md-12 text-center">
             { mnBankNmList && ccy && country && linerId && pdfName &&
              <a href={`/pdf/Bank/${pdfName}.pdf`} target="_blank" className="center"><FontAwesomeIcon icon={faDownload} className="ps-2 pe-2" style={{color:'#f5822a'}}></FontAwesomeIcon>Download Payment Manual</a>}
              {!pdfName&&<span>Use the same reference which is used in Bank while transferring the funds</span>}
            </div>
          </div> 
             
           

        </Modal.Body>
        
      </Modal>
};

export default PaymentRefUserGuid;


