import { useEffect } from "react"
import { Modal, Button, ModalTitle } from "react-bootstrap"
import { Base64Encode } from "../encrypt-util";
import axiosInstance from "../axiosSetup";
import { AppDispatch, RootState } from "../app/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hideNotificationModal, showNotificationModal } from "../app/common/slices/navbarTop";

function initialSetup() {

    if (!('serviceWorker' in navigator)) {
        console.warn('Service workers are not supported in this browser.');
        return;
    }

    navigator.serviceWorker.register('/sw.js').then(
        () => {
            if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
                console.warn('Notifications aren\'t supported.');
                return;
            }
            if (!('PushManager' in window)) {
                console.warn('Push messaging isn\'t supported.');
                return;
            }

            navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                serviceWorkerRegistration.pushManager
                    .getSubscription()
                    .then(function (subscription) {
                        if (!subscription) {
                            subscribe();
                            return;
                        }

                        sendSubscriptionToServer(subscription);
                    }).catch(function (err) {
                        console.warn('Error during getSubscription()', err);
                    });
            });
        }
    );
    //new Notification("ODeX", { body: `Hi ${user}. Welcome to ODeX` });
}

function base64UrlToUint8Array(base64UrlData:string) {
    const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
    const base64 = (base64UrlData + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const buffer = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
}

function subscribe() {
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: base64UrlToUint8Array('BCOPn6Ei_z97_roIUupL03_D2J4DY-q9BMhzIqMcZ1aCSerm722VqUlxqksQD_4nIwgdH2AaDYMCSZQ9XT2u6D4')
        }).then(function (subscription) {
            return sendSubscriptionToServer(subscription);
        }).catch(function (e) {
                if (Notification['permission'] === 'denied') {
                    console.warn('Permission for Notifications was denied');
                } else {
                    console.error('Unable to subscribe to push.', e);
                }
            });
    });
}

function sendSubscriptionToServer(subscription:PushSubscription) {

    var key:any = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth:any = subscription.getKey ? subscription.getKey('auth') : '';

    const uKey :any = new Uint8Array(key)
    const uAuth:any = new Uint8Array(auth) 

    const origin = document.location.origin;
    return axiosInstance.post(`${origin}/api/notification-management/v1/subscription/subscribe`,
        {
            endpoint: subscription.endpoint,
            key: key ? Base64Encode(String.fromCharCode.apply(null, uKey)) : '',
            auth: auth ? Base64Encode(String.fromCharCode.apply(null, uAuth)) : ''
        }
    );
}

const NotificationModal = () => {
    const state = useSelector((state:RootState)=>state.navbarTop.notificationModal)
    const dispatch = useDispatch<AppDispatch>()
    const profileDetails = useSelector((state:RootState)=>state.userProfile.profile)

    useEffect(() => {
        if(profileDetails.partnerType == 'customer'){
        dispatch(showNotificationModal({}))
        if(Notification.permission == 'granted'){
            initialSetup()
        }
    }
    }, [profileDetails])

    return <Modal show={state.show} onHide={() => { dispatch(hideNotificationModal()) }}>
        <Modal.Header closeButton>
            <ModalTitle>
                Notification
            </ModalTitle>
        </Modal.Header>
        <Modal.Body className="mb-3">
            {state.message??"Allow us to send you notifications"}
        </Modal.Body>
        {state.buttons&&<Modal.Footer>
            <Button
                variant="secondary"
                onClick={() => dispatch(hideNotificationModal())}
            >Later</Button>
            <Button
                onClick={()=>{
                    dispatch(hideNotificationModal())
                    Notification.requestPermission().then(function (permission) {
                        if (permission === 'granted') {
                            initialSetup();
                        }
                    });
                }}
            >Subscribe</Button>
        </Modal.Footer>}
    </Modal>
}

export default NotificationModal

export function unsubscribeNotification(){
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
            if(subscription)sendUnsubscribeRequestToServer(subscription)
        }
    )})
}

function sendUnsubscribeRequestToServer(subscription: PushSubscription) {

    var key:any = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth:any = subscription.getKey ? subscription.getKey('auth') : '';

    const uKey :any = new Uint8Array(key)
    const uAuth:any = new Uint8Array(auth) 

    const origin = document.location.origin;

    return axiosInstance.post(`${origin}/api/notification-management/v1/subscription/unsubscribe`, {
        endpoint: subscription.endpoint,
        key: key ? Base64Encode(String.fromCharCode.apply(uKey)) : '',
        auth: auth ? Base64Encode(String.fromCharCode.apply(uAuth)) : ''
        });
}
