import axios, { AxiosError } from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const csrfMeta = document.querySelector<HTMLMetaElement>("meta[name='_csrf']");

const userToken = document.querySelector<HTMLMetaElement>(
  "meta[name='user-token']"
);
const windowLoc = window.location.href.indexOf('.odexglobal.com');
const windowurl = window.location.href.substring(0, windowLoc);
console.log(process.env?.REACT_APP_API_GATEWAY_HOST, 'ENV_VAR')
const axiosInstance = axios.create({
  //baseURL: process.env.REACT_APP_API_GATEWAY_HOST,

  /*  'https://qctest.odexglobal.com', */
  baseURL:
    process.env?.REACT_APP_API_GATEWAY_HOST || `${windowurl}.odexglobal.com`,
  withCredentials: false,
});

// export const token = document.querySelector<HTMLMetaElement>(
//   "meta[name='user-token']"
// );

// if (process.env.NODE_ENV === 'development') {
//   axiosInstance.defaults.headers.common[
//     'authorization'
//   ] = `Bearer ${process.env.REACT_APP_DEV_TOKEN}`;
// }

// if (process.env.NODE_ENV === 'production' && csrfMeta !== null) {
//   axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = csrfMeta.content;
// }

const getHeader = () => {
  if (process.env.NODE_ENV === 'development') {
    try {
      // Do not provide token value directly in axiosSetup.ts

      // If any error regarding the "env-local.json" file.

      // Create a file in /src directory with name "env-local.json". (/src/env-local.json)

      /* Example 
          {
            "devToken": "eyJraWQiOiIxODU3YjA3YS1hOTUzLTQwZTgtODhhOC1lZWVlOTZjODU5MzMiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjoid2ViLWFwcCIsIm5iZiI6MTY5MzgyNjU4NCwiaXNzIjoiaHR0cHM6Ly9hdXRoLmQxLmRldi5vZGV4Z2xvYmFsLmNvbSIsIm5hbWUiOiJraXNhbiIsImV4cCI6MTY5MzgzMDE4NCwiaWF0IjoxNjkzODI2NTg0LCJlbWFpbCI6Imtpc2FuQG9kZXguY28ifQ.FCSzvAATNbxv8Good1MPikOZxeebQFXe2FOSt1b8bRJQkNh8XHswjc8BUMqej-HDPQNU93EVP7womN-1IrJp2gdDgaKyi0LnuLoG7Vm2Cio1pY6JaELU0O3MdTY145ovRQP4hSj7gj_DeiGeRsZBvsJcwXpKJ-nur9ExOootLUm7ttBz7-wjrKAIGmPelhrnmYuGAkffCUvueK04J9leE01xJHrMQ934zYKLIyGVo3t7Rsx2eQNyZUq0Jybl3SqE4B-fB0jbF_vKo_2PO1HGjNa0-LBiK7VC1EnBoXsDWIohtd_-QSrMa74AlXBfQyBaS_a4RjLuUqO0v6n8Osz__w"
          }
      */

      const token = require('./env-local.json').devToken;

      return {
        authorization: `Bearer ${token}`,
      };
    } catch (error) {
      console.error('DEV TOKEN GET ERROR: ', error);
      console.error('Please add env-local.json with devToken value');

      return {
        authorization: '',
      };
    }
  } else if (process.env.NODE_ENV === 'production' && csrfMeta !== null) {
    return {
      authorization: `Bearer ${userToken?.content}`,
      'X-CSRF-TOKEN': csrfMeta.content,
    };
  } else {
    return {};
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
    };

    const manipulateMethods: string[] = [
      'GET',
      'PATCH',
      'POST',
      'PUT',
      'DELETE',
    ];

    if (
      config.method &&
      manipulateMethods.includes(config.method.toUpperCase())
    ) {
      config.headers = {
        ...config.headers,

        'Accept-Language':
          JSON.parse(
            window?.localStorage?.getItem?.('@user-selected-datacountry') ||
              '{}'
          )?.country || null,

        ...getHeader(),
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const status = axiosError.response.status;
      if (status === 401) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const axiosCacheInstance = setupCache(axiosInstance, {
  ttl: 2000,
  interpretHeader: false,
  methods: ["head","get","post"],
})

export const axiosCache = setupCache(axios.create(), {
  ttl: 2000,
  interpretHeader: false,
  methods: ["head","get","post"],
})

export default axiosInstance;
