import { Card } from 'react-bootstrap';
import dataTableBgImg from '../../../src/assets/img/illustrations/cornerRight-1.png';
import dataTableBgImgLeft from '../../../src/assets/img/illustrations/corner-1.png';
import { Outlet, matchPath, useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';

export default function DashboardContainer({
  children,
  inner,
  sideWidth,
  middleChildren,
  innerOffset = 50,
  middleScroll = true,
}: {
  children: any;
  inner?: boolean;
  sideWidth?: number;
  middleChildren?: any;
  innerOffset?: number;
  middleScroll?: boolean;
}) {
  const location = useLocation();
  inner =
    inner ??
    (location.pathname.includes('/bl/') &&
      !matchPath('/bl/:blNum/', location.pathname));

  const endCard = inner ? sideWidth ?? 0 : 0;
  const middleCard = inner ? 12 - endCard : sideWidth ?? 0;
  const mainCard = inner ? 0 : 12 - middleCard;

  const [mainHide, setMainHide] = useState(mainCard === 0);
  const [middleHide, setMiddleHide] = useState(middleCard === 0);
  const [endHide, setEndHide] = useState(endCard === 0);

  const mainTimer = useRef<any>()
  const middleTimer = useRef<any>()

  useEffect(() => {
    if (mainCard === 0) {
      mainTimer.current = setTimeout(() => {
        setMainHide(true);
        mainTimer.current = null
      }, 500);
    } else {
      if(mainTimer.current) clearTimeout(mainTimer.current)
      mainTimer.current = null
      setMainHide(false);
    }

    if (middleCard === 0) {
      middleTimer.current = setTimeout(() => {
        setMiddleHide(true);
        middleTimer.current = null
      }, 500);
    } else {
      if(middleTimer.current)clearTimeout(middleTimer.current)
      middleTimer.current = null
      setMiddleHide(false);
    }

    if (endCard === 0) {
      setEndHide(true);
    } else setEndHide(false);

  }, [mainCard, middleCard, endCard]);

  return (
    <Card
      className='w-100 d-flex flex-row overflow-hidden'
      style={{ height: '90vh' }}
    >
      <div
        className='bg-holder bg-card'
        style={{
          backgroundImage: `url(${dataTableBgImg})`,
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      />

      <div
        className={`h-100 col-lg-${mainCard} smoothDiv m-0 p-0`}
        style={mainHide?{display:'none'}:{ zIndex: 1, alignSelf: 'flex-end' }}
      >
        {children}
      </div>

      <Card
        className={`h-100 col-lg-${
          inner ? 12 : middleCard
        } smoothDiv flex-row-reverse overflow-hidden`}
        style={{ height: '90vh' }}
      >
        <Card.Body
          className={`col-lg-${inner ? 3 : 12} smoothDiv m-0 p-0 ${
            middleScroll ? 'overflow-auto' : ''
          }`}
        >
          <div
            className='bg-holder bg-card'
            style={
              middleCard === 0
                ? {}
                : {
                    backgroundImage: `url(${
                      inner ? dataTableBgImg : dataTableBgImgLeft
                    })`,
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem',
                    backgroundPosition: inner ? 'left' : 'right',
                    zIndex: -1,
                  }
            }
          />
          {middleHide || (middleChildren ?? <Outlet />)}
        </Card.Body>

        <Card
          className={`col-lg-${endCard} smoothDiv overflow-hidden`}
          style={{
            position: 'absolute',
            top: `${innerOffset}px`,
            bottom: '0px',
          }}
        >
          {
            <div
              className='bg-holder bg-card'
              style={
                endCard === 0
                  ? {}
                  : {
                      backgroundImage: `url(${dataTableBgImgLeft})`,
                      borderTopRightRadius: '0.375rem',
                      borderBottomRightRadius: '0.375rem',
                      backgroundPosition: 'right',
                      zIndex: -1,
                    }
              }
            />
          }

          <Card.Body
            className='m-0 p-0 overflow-auto'
            style={{ minHeight: '100%' }}
          >
            {endHide || <Outlet />}
          </Card.Body>
        </Card>
      </Card>
    </Card>
  );
}
