const AgentDelegationTNC = () => {
  return (
    <div>
      <p>
        <b>Shipping-Line</b>
      </p>
      <p>
terms and conditions here...
      </p>

      <p>
      </p>
    </div>
  );
};

export default AgentDelegationTNC;