import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import bg1 from "../assets/images/landing-bg.jpeg";
import { Card, Button } from "react-bootstrap";
import {
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
// import {
//   faFileDownload,
// } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons';


const AuthCardLayoutNew = () => {


  const [configList, setConfigList] = useState({
    JO: { pdf: "/pdf/registration/JO/JORegistration.pdf" },
    KE: { pdf: "/pdf/registration/KE/KERegistration.pdf" },
    MY: { pdf: "/pdf/registration/MY/myRegistration.pdf" },
    SG: { pdf: "/pdf/registration/SG/customerRegistrationSingapore.pdf" },
    US: {
      pdf: "/pdf/registration/ODeXRegistrationUpdated.pdf",
      video: "https://www.youtube.com/watch?v=BTOB5CzNryY&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=13"
    },
    ZA: { pdf: "/pdf/registration/ZA/zaODeXRegistration.pdf" }
  })

  const country = useSelector(
    (state) => state.registration.selectedCountry
  );

  const pdfUrl = configList[country]?.pdf;
  const videoUrl = configList[country]?.video;

  return (

    <div
      className="d-flex overflow-hidden justify-content-center 
    align-content-center flex-row"
      style={{ height: "100vh" }}
    >
      <div
        className="col-lg-12"
        style={{
          height: "100vh",
          backgroundImage: `url(${bg1})`,
          backgroundSize: "100%",
          backgroundPosition: "45% 100%",
          filter: "brightness(75%)",
          position: "absolute",
          zIndex: -1
        }}
      />

      <Card className="col-md-9 my-3 overflow-hidden position-relative">
        <div className="overflow-auto p-3 pt-3">
          <Outlet />
        </div>
        <div
          className='w-100 sticky-bottom'
          style={{
            fontSize: '14.5px',
            backgroundColor: 'rgba(80, 80, 80)',
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
          }}>
          © Copyright 2018-2024 EA Technologies. All rights reserved
        </div>
        <div
          style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            zIndex: 1000,
          }}
        >
          {(pdfUrl || videoUrl) && (


            <Button
              variant='ms-3 btn btn-falcon-primary z-inxe-agent btn-sm'
              style={{ cursor: 'default' }}
              size='sm'
              title='Help'
              className='ms-3 mt-2'
            
            >
              <>

                <span className='me-2'>
                  Help
                </span>
                {videoUrl &&
                  <span onClick={() => window.open(videoUrl, '_blank')} to={''}
                    style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2' />
                  </span>
                }

                {pdfUrl &&
                  <span onClick={() => window.open(pdfUrl, '_blank')} to={''}
                    style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>
                }

              </>

            </Button>

          )}
        </div>
      </Card>
    </div>

  );
};
AuthCardLayoutNew.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default AuthCardLayoutNew;
