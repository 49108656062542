import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


import {
  fetchAddressSuggestions,
  getCompanyTypes,
  getRegistrationDetails,
  getRegistrationDocuments,
  getRegistrationPartnerDocConfig,
  getRegistrationTaxDocConfig,
} from "./actions/Registration";

export interface RegistrationDetails {
  filterCountries: string;
  text: string;
}
export interface IRegistrationList {
  list: RegistrationDetails[];
}

export interface IRegistrationAddressSuggesstion {
  text: string;
  placeId: string;
}

export interface RegistrationSlice {
  data: IRegistrationList;
  // showColumnSettings: boolean;
  registrationAddressSuggesstion: IRegistrationAddressSuggesstion[];
  selectedAddressResult: string;
  selectedPlaceId: string;
  isAddressSuggesstionsLoading: boolean;
  taxId: any;
  companyName: any;
  address: any;
  countryOptions: any;
  linerOptions: any;
  documentOptions: Array<{
    text: string;
    value: number;
    m: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
    };
  }>;
  companyTypeOptions: Array<any>;
  partnerTable: Array<{
    rowNumber: any;
    customerCode: String;
    liner: String;
    attachmentName: String;
    serverPath: String | null;
    serverName: String | null;
    loading: number;
    remove: true;
    m: boolean;
    error:{
      attachment: boolean;
      customerCode: boolean;
    }
  }>;
  documentTable: Array<{
    regPartnerDocumentId?:any,
    rowNumber: any;
    docId: Number;
    number: String;
    issueDate: Date | null;
    validityDate: Date | null;
    attachmentName: String;
    serverPath: String | null;
    serverName: String | null;
    loading: number;
    m: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
    };
    error: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
    };
  }>;
  taxDocument: {
    name: string;
    serverPath: string | null;
    serverName: string | null;
    loading: number;
    m: boolean;
    error: boolean
  };
  selectedCountry:string;
}

const initialState: RegistrationSlice = {
  data: {
    list: [],
  },

  selectedAddressResult: "",
  selectedPlaceId: "",
  isAddressSuggesstionsLoading: false,

  registrationAddressSuggesstion: [],
  taxId: {},
  companyName: {},
  address: {},
  countryOptions: [],
  linerOptions: [],
  companyTypeOptions: [],
  taxDocument: {
    name: "",
    serverPath: null,
    serverName: null,
    loading: 0,
    m: true,
    error: false
  },
  partnerTable: [
    {
      rowNumber: crypto.randomUUID(),
      customerCode: "",
      liner: "",
      attachmentName: "",
      serverName: null,
      serverPath: null,
      loading: 0,
      remove: true,
      m: true,
      error:{
        attachment: false,
        customerCode: false
      }
    },
  ],
  documentTable: [],
  documentOptions: [],
  selectedCountry: '',
};

export const RegistrationReducer = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateSelectedAddressResult: (state, action: PayloadAction<any>) => {
      state.selectedAddressResult = action.payload.text;
      state.selectedPlaceId = action.payload.placeId;
    },
    updateTax: (state, action: PayloadAction<any>) => {
      state.taxId = action.payload;
    },
    updateCompanyName: (state, action: PayloadAction<any>) => {
      state.companyName = action.payload;
    },
    updateAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload;
    },
    updateCountryOptions: (state, action: PayloadAction<any>) => {
      state.countryOptions = action.payload;
    },
    updateSelectedCountry: (state, action: PayloadAction<any>) => {
      state.selectedCountry = action.payload;
    },
    updateLinerOptions: (state, action: PayloadAction<any>) => {
      state.linerOptions = action.payload;
    },

    addPartnerTableRow: (state, action) => {
      state.partnerTable.push({
        rowNumber: action.payload.key,
        customerCode: "",
        liner: state.linerOptions[0]?.value ?? "",
        attachmentName: "",
        serverName: null,
        loading: 0,
        serverPath: null,
        remove: true,
        m:action.payload.m,
        error:{
          attachment: false,
          customerCode: false
        }
      });
    },
    addDocumentTableRow: (state) => {
      state.documentTable.push({
        rowNumber: crypto.randomUUID(),
        docId: state?.documentOptions?.find?.((i)=>
        state.documentTable.findIndex((j)=>j.docId == i.value)==-1)?.value??state?.documentOptions?.[0]?.value ?? 0,
        number: "",
        issueDate: null,
        validityDate: null,
        attachmentName: "",
        serverName: null,
        loading: 0,
        serverPath: null,
        m: state?.documentOptions?.[0]?.m ?? {
          number: true,
          issueDate: true,
          validityDate: true,
          attachment: false,
        },
        error: {
          number: false,
          issueDate: false,
          validityDate: false,
          attachment: false
        }
      });
    },
    removePartnerTableRow: (state, action) => {
      if (state.partnerTable.length <= 1) {
        state.partnerTable = [
          {
            rowNumber: action.payload.key,
            customerCode: "",
            liner: state.linerOptions[0]?.value ?? "",
            attachmentName: "",
            serverName: null,
            loading: 0,
            serverPath: null,
            remove: true,
            m:action.payload.m,
            error:{
              attachment: false,
              customerCode: false
            }
          },
        ];
        return;
      }
      state.partnerTable = state.partnerTable.filter(
        (i) => i.rowNumber !== action.payload.rowNumber
      );
    },
    removeDocumentTableRow: (state, action) => {
      state.documentTable = state.documentTable.filter(
        (i) => i.rowNumber !== action.payload.rowNumber
      );
    },
    setPartnerTableRow: (state, action) => {
      const row: any = state.partnerTable.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      if (!row) return;
      Object.entries(action.payload).forEach(([key, val]) => {
        if (Object.hasOwn(row, key)) row[key] = val;
      });
    },
    setPartnerTableCols: (state, action) => {
      // const row: any = state.partnerTable.find(
      //   (x) => x.rowNumber == action.payload.rowNumber
      // );
      // if (!row) return;
      state.partnerTable.forEach((item:any)=>{
        Object.entries(action.payload).forEach(([key, val]) => {
          if (Object.hasOwn(item, key)) item[key] = val;
        });
      })

    },
    setDocumentTableRow: (state, action) => {
      const row: any = state.documentTable.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      if (!row) return;
      Object.entries(action.payload).forEach(([key, val]) => {
        if (Object.hasOwn(row, key)) row[key] = val;
        if (key == 'docId'){
          row.m = state.documentOptions.find((item)=>item.value == val)?.m??{
            number: true,
            issueDate:true,
            validityDate:true,
            attachment:false,
          }
        }
      });
    },
    updateTaxDocument: (state, action) => {
      state.taxDocument = { ...state.taxDocument, ...action.payload };
    },
    validationCheckRegistration:(state) =>{
      
        state.taxDocument.error = !!(state.taxDocument.m && (state.taxDocument.serverPath ==null || state.taxDocument.loading != 100))
      
      state.documentTable.forEach((item)=>{
        
          item.error.attachment = !!((item.serverPath ==null || item.loading !== 100))
          item.error.issueDate = !!(item.m.issueDate && item.issueDate == null)
          item.error.validityDate = !!(item.m.validityDate && item.validityDate == null)
          item.error.number = !!(item.m.number && item.number == '')
      })

      state.partnerTable.forEach((item)=>{
          item.error.attachment = !!(item.m && item.customerCode && (item.serverPath == null || item.loading != 100))
          item.error.customerCode = !!(item.serverPath && item.customerCode.trim() =='')
      })
    },
    clearRegistrationFiles:(state)=>{
      state.taxDocument.name =''
      state.taxDocument.loading=0
      state.taxDocument.serverPath=null
      state.taxDocument.serverName=null

      state.partnerTable.forEach((row)=>{
        row.attachmentName = ''
        row.serverName = null
        row.serverPath = null
        row.loading = 0
      })

      state.documentTable.forEach((row)=>{
        row.attachmentName = ''
        row.serverPath = null
        row.serverName = null
        row.loading = 0
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddressSuggestions.pending, (state, action) => {
      state.isAddressSuggesstionsLoading = true;
    });

    builder.addCase(fetchAddressSuggestions.fulfilled, (state, action) => {
      state.isAddressSuggesstionsLoading = false;
      state.registrationAddressSuggesstion = action.payload;
    });

    builder.addCase(getCompanyTypes.fulfilled, (state, action) => {
      state.companyTypeOptions = action.payload;
    });

    builder.addCase(getRegistrationDetails.pending, (state)=>{
      state.documentTable = []
      state.taxDocument = {
        name: '',
        serverPath: null ,
        serverName: null ,
        loading: 0,
        m: false,
        error: false
      }
      state.partnerTable = [
        {
          rowNumber: crypto.randomUUID(),
          customerCode: "",
          liner: state.linerOptions[0]?.value ?? "",
          attachmentName: "",
          serverName: null,
          serverPath: null,
          loading: 0,
          remove: true,
          m: true,
          error:{
            attachment: false,
            customerCode: false
          }
        },
      ]
    })

    builder.addCase(getRegistrationDetails.fulfilled, (state, action:any)=>{
      state.documentTable = action.payload?.data?.customerDocumentInputs?.map((item:any)=>({
          regPartnerDocumentId: item.partnerDocumentId,
          rowNumber: crypto.randomUUID(),
          docId: item.docId,
          number: item.docNo,
          issueDate: item?.issueDate?new Date(item.issueDate):null,
          validityDate: item?.validTillDate?new Date(item.validTillDate):null,
          attachmentName: item.fileName,
          serverName: null,
          serverPath: null,
          loading: 0,
          m: {
            number: true,
            issueDate: true,
            validityDate: true,
            attachment: false,
          },
          error: {
            number: false,
            issueDate: false,
            validityDate: false,
            attachment: false,
          }
        
      }))??[]
      state.taxDocument = {
        name: action.payload.data?.taxFileName??'',
        serverPath: null ,
        serverName: null ,
        loading: 0,
        m: false,
        error: false
      }
      state.partnerTable = action.payload?.data.customerMapping?.map((item:any)=>({
        rowNumber: crypto.randomUUID(),
        customerCode: item.mapCode,
        liner: item.linerId,
        attachmentName: item.fileName,
        serverPath: null,
        serverName: null,
        loading: 0,
        remove: false,
        m:false,
        error:{
          attachment: false,
          customerCode: false
        }

      }))??[
        {
          rowNumber: crypto.randomUUID(),
          customerCode: "",
          liner: state.linerOptions[0]?.value ?? "",
          attachmentName: "",
          serverName: null,
          serverPath: null,
          loading: 0,
          remove: true,
          m: true,
          error:{
            attachment: false,
            customerCode: false
          }
        },
      ]
    })

    builder.addCase(
      getRegistrationDocuments.fulfilled,
      (state, action: any) => {
        state.documentOptions = action.payload.map((item: any) => ({
          text: item.docName,
          value: item.docId,
          m: {
            number: item.docNo == "Y",
            issueDate: item.issueDate == "Y",
            validityDate: item.validTillDate == "Y",
            attachment: false,
          },
        }));
        if(state.documentTable?.[0]?.regPartnerDocumentId != undefined){return}
        state.documentTable = action.payload
          .filter((i: any) => i.isMandatory == "Y")
          .map((item: any) => ({
            rowNumber: crypto.randomUUID(),
            docId: item.docId,
            number: "",
            issueDate: null,
            validityDate: null,
            attachmentName: "",
            serverName: null,
            serverPath: null,
            loading: 0,
            m: {
              number: item.docNo == "Y",
              issueDate: item.issueDate == "Y",
              validityDate: item.validTillDate == "Y",
              attachment: true,
            },
            error: {
              number: false,
              issueDate: false,
              validityDate: false,
              attachment: false,
            }
          }));
      }
    );

    builder.addCase(getRegistrationTaxDocConfig.fulfilled, (state, action) => {
      state.taxDocument.m = action.payload == "Y";
    });

    builder.addCase(
      getRegistrationPartnerDocConfig.fulfilled,
      (state, action) => {
        
      }
    );
  },
});

export const {
  updateSelectedAddressResult,
  updateTax,
  updateCompanyName,
  updateAddress,
  updateCountryOptions,
  updateSelectedCountry,
  updateLinerOptions,
  addPartnerTableRow,
  removePartnerTableRow,
  setPartnerTableRow,
  setDocumentTableRow,
  updateTaxDocument,
  addDocumentTableRow,
  removeDocumentTableRow,
  validationCheckRegistration,
  setPartnerTableCols,
  clearRegistrationFiles
} = RegistrationReducer.actions;

export default RegistrationReducer.reducer;
