import { useEffect, useState } from "react"

const useCDNCountry = ()=> {
  const [country, setCountry] = useState('');
    useEffect(()=>{
        const countryMeta = document.querySelector<HTMLMetaElement>("meta[name='user-country']");
        setCountry(countryMeta?.content??'');
        console.log(countryMeta?.content??'', 'CDN META COUNTRY')

    },[])
    // allow paypal on test environment
   if(process.env?.REACT_APP_API_GATEWAY_HOST?.includes?.("qctest") || 
   window.location.href.includes("qctest")) return 'US' 
   return country

}
export default useCDNCountry