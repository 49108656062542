const retry = async (callback:Function, retries = 3, delay = 0) => {
    let attempts = 0;
    let lastError = null;
  
    while (attempts < retries) {
      try {
        const result = await callback(); // Call the provided callback
        return result; // Return the result if successful
      } catch (error) {
        lastError = error;
        attempts += 1;
  
        // Optional: Implement a delay before the next attempt
        if (attempts < retries) {
          await new Promise(res => setTimeout(res, delay)); // Delay
        }
      }
    }
  
    // After all retries, throw the last error
    throw lastError;
  };
  
  export default retry;