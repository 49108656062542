import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addDemurrage,
  getAttachmentsOnly,
  getDemurrageView,
} from "../../../dashboard/actions/instruction";

interface IDemurrageFormView {
  blId: any,
  senderEmail: string | null;
  country: string | null;
  intendedDate: string | null;
  remarks: string | null;
  userRemarks: string | null;
  status: string | null;
  list: any[];
  attList: any[];
  requestedPartnerName: string | null;
  dndInstructionId: number | string | null;
  groupLevel:number
}

interface IDemurrageContainerDetails {
  //vesselType: any;
  containerNo: string | null;
  IntendedDate: string | null;
}

interface IDemurrageListView {
  list: any[];
  next: string | null;
  previous: string | null;
}

export interface IDemurrageDetails {
  data: IDemurrageListView;
  isDemurrageDetailsLoading: boolean;
  dataLoading: boolean;
  demurrageForm: IDemurrageFormView;
  // containerDetails: IDemurrageContainerDetails;
}

const initialState: IDemurrageDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  isDemurrageDetailsLoading: false,
  demurrageForm: {
    blId:null,
    senderEmail: "",
    intendedDate: "",
    status: "",
    remarks: "",
    userRemarks:'',
    country: "",
    list: [],
    attList: [],
    requestedPartnerName: "",
    dndInstructionId: null,
    groupLevel:0,
  },
};

export const DemurrageSlice = createSlice({
  name: "demurrageView",
  initialState,
  reducers: {
    resetDemurrageViewSlice: (state, action: any) => {
      state.demurrageForm = initialState.demurrageForm;
    },
    updateDemurrageList: (state, action: PayloadAction<IDemurrageListView>) => {
      state.data = action.payload;
    },
    updateDemurrageListView: (state, action: PayloadAction<any>) => {
      // state.taxId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDemurrage.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addDemurrage.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getDemurrageView.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getDemurrageView.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.demurrageForm = {
        senderEmail: action?.payload?.data?.customerEmailId || "",
        remarks: action?.payload?.data?.remarks || "",
        userRemarks: action?.payload?.data?.userRemarks || '',
        country: action?.payload?.data?.country || "",
        status: action?.payload?.data?.status || "",
        intendedDate: new Date().toISOString(),
        list: action?.payload?.data?.list || [],
        requestedPartnerName: action?.payload?.data?.requestedPartnerNm || "",
        attList: action?.payload?.data?.attList || [],
        dndInstructionId: action?.payload?.data?.dndInstructionId || null,
        blId: action?.payload?.data?.blId,
        groupLevel:action?.payload?.data?.groupLevel??0
      };
      //state.demurrageForm = {
      // number: action?.payload?.number || '',
      //senderEmail: action?.payload.data?.customerEmailId || '',
      //IntendedDate: action?.payload.data?.customerEmailId,
      // state.data= action.payload;
      //};
    });
    builder.addCase(getDemurrageView.rejected, (state, action) => {});
    builder.addCase(getAttachmentsOnly.fulfilled,(state,action)=>{
      // setting back the 
      state.demurrageForm.attList = []
      if(!action.payload?.att)return
      state.demurrageForm.attList = action.payload.att
    })
  },
});
export const { updateDemurrageList, resetDemurrageViewSlice } =
  DemurrageSlice.actions;

export default DemurrageSlice.reducer;
