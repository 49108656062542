/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';

import { useEffect, useMemo, useRef, useState } from 'react';


import queryString from 'query-string';
import {
  matchPath,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  AESDecrypt,
  AESEncrypt,
} from '../../../encrypt-util';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';

import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateParams,
} from '../../common/components/DataTable/slices/DataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import {
  updateDashbaordName,
} from '../../common/slices/navbarTop';
import Header from '../../common/components/DetailsViewHeader';

import DashboardContainer from '../../../components/common/DashboardContainer';
import DocumentHistory from './DocumentHistory';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import { useBlDashName } from '../../../hooks/useDashName';
import DashboardRoot from '../dashboard/components/DashboardRoot';
import { toggleAddAgent, toggleAddAgentButton, toggleColumnSettingsDashboard, updateCurrentPageBlDashboard } from '../dashboard/slices/dashboardSlice';
import retry from '../../../helpers/axiosRetry';

const BlDetails = () => {
  
  const blDashboardName = useBlDashName() 
  const { blNum } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const rbac = useSelector((state:RootState)=>state.userProfile)

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPage
  );
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  const data = useSelector(
    (state: RootState) => state.dashboard.blDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.grids
  );
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );


  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageBlDashboard(page));
  };

  const topSearchState = useSelector(
    (state: RootState) => state.navbarTop.blTopSearchState
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || '';
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const dashboardDefinition =
      grids?.[0]?.columnConfigGrid || '';

    if (!dashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(dashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedBlDataRows
  );

  useEffect(() => {
    dispatch(updateDashbaordName(blDashboardName));
  }, []);

  useEffect(() => {
    if (refresh) loadDashboard(false);
    dispatch(updateDashbaordRefresh(false))
  }, [refresh]);

  const filterStateLocal = filterState?.['mainDashboard'];

  useEffect(() => {
    if (
      dataCountry &&
      linerId
    ) {
      // on first login sometimes
      // the grid response is blank 
      retry(()=>
        dispatch(
          getColumnViews({
            gridName: blDashboardName,
            country: dataCountry,
            linerId: linerId,
          })
        ).unwrap().then((data)=>{
          if(!data?.gridName) throw new Error()
        }), 3, 500
      )
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  useDebouncedEffect(
    () => {
      if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
        loadDashboard(true);
        setPreviousState(currentState);
      }
    },
    [currentState],
    700
  );

  useEffect(() => {
    loadDashboard(false);
  }, [currentPage, linerId]);

  const _getDashboardData = (fields: string[], filters: any, page: number) => {
    const sort = (queryString.parse(location.search)?.sort as string)
      ?.split?.(',')
      ?.join?.(' ');
      
    const sorts = sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['lastupdateddate desc'];
    
    const payload = {
      fields,
      filters,
      size: 20,
      sorts,
      page,
    };

    const partnerId =
      profileDetails.partnerType === 'customer'
        ? profileDetails.partnerId
        : null;

    const where = grids?.[0]?.whereClause
      ? AESEncrypt(grids?.[0]?.whereClause)
      : null;

    const parameter = selectedTopSearchFilter?.where
      ? {
          searchParameter: searchInputValue,
          searchWhere: selectedTopSearchFilter?.where,
        }
      : {};

    dispatch(
      getDashboardData({
        gridName: blDashboardName,
        payload,
        partnerId,
        where,
        dataCountry,
        parameter,
      })
    );
  };

  const loadDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }

        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = 
          (selectedTopSearchFilter?.id && searchInputValue.trim() != '' && 
          Object.keys(topSearchState).length != 0)?
          {[selectedTopSearchFilter?.id] : [searchInputValue.trim()]}:{};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            const filters = !selectedTopSearchFilter?.where
              ? finalFilterApiState
              : {};
              _getDashboardData([], filters, filterUpdated ? 1 : currentPage);
          }
        }

      }
    } else if (
      !location.pathname.includes('blslider/') &&
      !location.pathname.includes('bl/') &&
      location.pathname.includes('/home') &&
      ((profileDetails.partnerType === 'customer' &&
        profileDetails.partnerId &&
        grids?.[0]?.whereClause) ||
        profileDetails.partnerType === 'liner')
    ) {
      const filters = !selectedTopSearchFilter?.where ? topSearchState : {};
      _getDashboardData([], filters, filterUpdated ? 1 : currentPage);
    }
  };

  const showColumnSettings = useSelector(
    (state: RootState) => state.dashboard.showColumnSettings
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
  }, [dataCountry]);

  const sideWidth = useMemo(
    () =>
      Object.entries({
        [`/home`]: 0,
      }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9,
    [location.pathname]
  );
  return (
    <DashboardContainer
      sideWidth={sideWidth}
      inner={!!matchPath('/bl/:blNum/:encrypt/:encryptdoc', location.pathname ) || 
        !!matchPath('/bl/:blNum/:encrypt', location.pathname ) ||
        !!matchPath('/bl/:blNum/unpaid', location.pathname )
      }
      middleScroll={!location.pathname.includes('bl')}
      middleChildren={
        location.pathname.includes('bl/')?
          <div className={`flex-1 h-100 smoothDiv`}>
              <Header
                backNavRoute={'/home'}
                paymentStatus={''}
                instructionStatus={''}
                releaseStatus={''}
              />
            <DocumentHistory />
          </div>
          :<Outlet/>
               }
    >
      <DashboardRoot />
      {columns?.length > 0 && (
        <DataTable
        data={data?.result || []}
        columns={columns}
        sortable={true}
        searchable={false}
        showColumnFilter={false}
        selection={
          rbac?.rbacDetails?.multiInstructCreate &&
          rbac?.rbacDetails?.multiInstructCreate === 'Y'
            ? true
            : false
        }
        perPage={20}
        pagination
        numberPagination
        maxPage={data?.maxPage || 100}
        hasNext={data?.hasNext}
        currentPage={currentPage}
        showExport={false}
        showColumnSettings={showColumnSettings}
        onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
        gridName={blDashboardName}
        onHide={()=>{
          onHideColumnSettings()
        }}
        columnSettingsPersistKey='@odex/maintable/v6'
        onCustomSelectChange={(d) => {
          if (d?.list?.length > 0) {
            dispatch(toggleAddAgentButton(true));
          } else {
            dispatch(toggleAddAgentButton(false));
            dispatch(toggleAddAgent(false));
          }
        }}
        // seekPagination={true}
        // previous={data.previous ? data.previous : ""}
        // next={data.next ? data.next : ""}
        customSort={true}
        shortPrepend='bl_'
        uniqueFilterKey='mainDashboard'
        staticHiddenCols={configs?.staticHiddenCols || []}
        defaultHiddenCols={configs?.defaultHiddenCols || []}
        autoHeight={false}
        tableMinHeight={400}
        // gridId={1}
        gridId={grids?.[0]?.gridId || 0}
        dataLoading={false}
        dataCountry={dataCountry}
        selectionInitialState={selectionInitialState}
      />
      )}
    </DashboardContainer>
  );
};

export default BlDetails;
